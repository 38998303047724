import { INSURANCE_TYPES_NAMES } from '../constants';

import CarSvg from '../images/onboarding/car.svg';
import ContentSvg from '../images/onboarding/content.svg';
import DisabilitySvg from '../images/onboarding/disability.svg';
import HealthSvg from '../images/onboarding/health.svg';
import HomeAndContentsSvg from '../images/onboarding/home-and-contents.svg';
import HomeSvg from '../images/onboarding/home.svg';
import IncomeSvg from '../images/onboarding/income.svg';
import LifeSvg from '../images/onboarding/life.svg';
import MotorcycleSvg from '../images/onboarding/motorcycle.svg';
import MortgageSvg from '../images/onboarding/mortgage.svg';
import PetSvg from '../images/onboarding/pet.svg';
import RentersSvg from '../images/onboarding/renters.svg';
import TraumaSvg from '../images/onboarding/trauma.svg';
import TravelSvg from '../images/onboarding/travel.svg';
import BoatSvg from '../images/onboarding/Boat.svg';
import RedundancySvg from '../images/onboarding/Redundancy.svg';

import BlueCircleCarSvg from '../images/blue-circle-icons/car.svg';
import BlueCircleContentsSvg from '../images/blue-circle-icons/contents.svg';
import BlueCircleHomeSvg from '../images/blue-circle-icons/house.svg';
import BlueCircleHomeContentsSvg from '../images/blue-circle-icons/house-contents.svg';
import BlueCircleLifeSvg from '../images/blue-circle-icons/life.svg';
import BlueCircleMotorcycleSvg from '../images/blue-circle-icons/motorcycle.svg';

export const INSURANCE_TYPES_ICONS = {
  [INSURANCE_TYPES_NAMES.VEHICLE]: CarSvg,
  [INSURANCE_TYPES_NAMES.MOTORCYCLE]: MotorcycleSvg,
  [INSURANCE_TYPES_NAMES.CONTENT]: ContentSvg,
  [INSURANCE_TYPES_NAMES.DISABILITY]: DisabilitySvg,
  [INSURANCE_TYPES_NAMES.HEALTH]: HealthSvg,
  [INSURANCE_TYPES_NAMES.HOME_CONTENTS]: HomeAndContentsSvg,
  [INSURANCE_TYPES_NAMES.HOME]: HomeSvg,
  [INSURANCE_TYPES_NAMES.INCOME]: IncomeSvg,
  [INSURANCE_TYPES_NAMES.LIFE]: LifeSvg,
  [INSURANCE_TYPES_NAMES.LANDLORD]: HomeSvg,
  [INSURANCE_TYPES_NAMES.MORTGAGE]: MortgageSvg,
  [INSURANCE_TYPES_NAMES.PET]: PetSvg,
  [INSURANCE_TYPES_NAMES.RENTERS]: RentersSvg,
  [INSURANCE_TYPES_NAMES.TRAUMA]: TraumaSvg,
  [INSURANCE_TYPES_NAMES.TRAVEL]: TravelSvg,
  [INSURANCE_TYPES_NAMES.BOAT]: BoatSvg,
  [INSURANCE_TYPES_NAMES.REDUNDANCY]: RedundancySvg,
  [INSURANCE_TYPES_NAMES.CANCER]: RedundancySvg,
};

export const INSURANCE_TYPE_ICONS_BLUE_CIRCLE = {
  [INSURANCE_TYPES_NAMES.VEHICLE]: BlueCircleCarSvg,
  [INSURANCE_TYPES_NAMES.CONTENT]: BlueCircleContentsSvg,
  [INSURANCE_TYPES_NAMES.HOME]: BlueCircleHomeSvg,
  [INSURANCE_TYPES_NAMES.HOME_CONTENTS]: BlueCircleHomeContentsSvg,
  [INSURANCE_TYPES_NAMES.LIFE]: BlueCircleLifeSvg,
  [INSURANCE_TYPES_NAMES.MOTORCYCLE]: BlueCircleMotorcycleSvg,
};
